.overview-items {
  ol {
    margin-bottom: 0;
    line-height: 1.65em;
  }
}

.bigger-company-logotype {
  width: 27px !important;
  border-radius: 5px;
  margin-left: 2px;
}

.description_preview_container {
  p,
  li {
    color: rgba($main-blue, 0.85);
    font-size: 16.4px;
  }
  &:first-of-type {
    margin-top: 8px;
  }
}

.influence_preview_description {
  .general-edit-button {
    position: absolute;
    top: 30px;
  }
}

.preview-content {
  a {
    color: $light-blue;
  }
  p {
    margin: 0;
  }
}

.preview-organisation-item {
  .general-edit-button {
    position: absolute;
  }
  img {
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    margin-top: -4px;
    border-radius: 3px;
  }
}

.preview-contentype-item {
  padding-left: 29px;
}

.preview-info-items {
  border-top: 1px solid rgba($main-grey, 0.5);
  border-bottom: 1px solid rgba($main-grey, 0.5);
}

.preview-date-item {
  background: url('#{$cdn-url}icon-upcoming-events.svg') 0px 4px /15px auto no-repeat;
  padding-left: 29px;
}

.preview-region-item {
  background: url('#{$cdn-url}20210817-location-icon.svg') 2px 4px /14px auto no-repeat;
  padding-left: 29px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.preview-contentype-policy-asks {
  background: url('#{$cdn-url}20210208-icon-policy-asks.svg') 0px 5px / auto 16px no-repeat;
}
.preview-contentype-briefing-material {
  background: url('#{$cdn-url}20210208-icon-briefing-material.svg') 2px 5px / auto 15px no-repeat;
}

.preview-info-items_edit {
  p {
    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
  .general-edit-button {
    top: 7px;
    right: 25px;
  }
}

.user-content-complete {
  .attachment-box {
    max-width: 65%;
  }
}

.influence-preview-title {
  .general-edit-button {
    vertical-align: middle;
    margin-top: -3px;
    display: inline-block;
  }
}

.preview-info-items_edit {
  padding-left: 16px;
}

.details-information {
  background-color: rgba($main-grey, 0.06);
}

.details-item {
  margin-bottom: 15px;
}

.organisation-name-spacing {
  line-height: 1.25;
  margin-top: 4px;
}

.key-information-title {
  padding: 12px 16px;
}

.key-information-attachments-icon {
  margin-bottom: -12px;
  transform: rotate(90deg);
  vertical-align: middle;
  display: inline-block;
  font-size: 18px;
}

.user-content-page-main {
  padding-left: 12px;
}

.details-icon {
  min-width: 20px;
  margin-right: 8px;
}

.published-content-description-base {
  font-family: 'Lato' !important;
  color: rgba($main-blue, 0.85) !important;
  font-size: 16.4px !important;
}

.published-content-description {
  @extend .published-content-description-base;
  line-height: 1.6;
  p,
  span {
    @extend .published-content-description-base;
  }
  ul {
    margin-top: 20px;
    li:has(li) {
      list-style-type: none !important;
    }
    li {
      list-style-type: disc !important;
    }
  }
}

.political-updates-nav {
  width: 250px;
  position: sticky;
  top: 0em;
  .icon-key-update {
    font-size: 15px;
  }
  .icon-parliamentary-record {
    margin-top: 2px;
  }
  .icon-library-material {
    font-size: 15px;
    margin: 1px 1px 0px -1px;
  }
  .icon-briefing-material,
  .icon-calendar {
    margin-right: -1px;
    margin-left: 1px;
  }
}

.political-updates-nav-item {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5em 2.5em 0.5em 1em;
  border-radius: 5px;
  transition: 0.2s ease-in;
  &:hover {
    background: rgba($main-grey, 0.2);
  }
}

.political-updates-nav-item-active {
  color: $light-blue;
  background: rgba($light-blue, 0.04);
  &:hover {
    background: rgba($light-blue, 0.04);
  }
}

.political-updates-nav-icon {
  width: 32px;
  display: inline-block;
}

.policymakers-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 12px;
}

.published-page-button {
  padding: 4px 15px;
}

.item-supporter {
  background: rgba($light-blue, 0.04);
  cursor: pointer;
  .icon-edit-pencil {
    top: 10px;
    right: 15px;
  }
}

.published-page-edit-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.political-updates-title {
  position: sticky;
  top: 0;
  width: calc(100% + 2em);
  margin-left: -1em;
  padding-left: 1em;
  z-index: 1000;
  padding-bottom: 0.75em;
}

.supporter-list-item-padding {
  padding: 16px 16px 14px 16px;
}

.skeleton-political-updates {
  margin-top: -8px;
}

.single-content-width,
.single-content-width-monitor {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .single-content-width {
    width: calc(100% - 250px);
  }
  .single-content-width-monitor {
    width: calc(100% - 272px);
  }
  .details-container {
    position: sticky;
    top: 0em;
  }

  .preview-organisation-item.action-state-hover {
    margin-right: -20px;
  }

  .preview-info-items_edit {
    padding: 0 70px 0 25px;
    p {
      &:nth-of-type(1) {
        margin-right: 35px;
      }
    }
    .general-edit-button {
      top: 16px;
    }
  }
  .preview-contentype-item {
    margin-left: 35px;
    margin-right: 17px;
  }

  .preview-region-item {
    padding-left: 26px;
  }

  .influence-preview-title {
    max-width: 65%;
  }

  .item-supporter {
    transition: 0.2s ease-in;
    &:hover {
      border: 1px solid rgba($main-blue, 0.4);
      .action-text {
        text-decoration: underline;
      }
    }
  }
}
