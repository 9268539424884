.reports-side-nav {
  [class^='awsui_root_'] {
    row-gap: 10px !important;
  }
  [class^='awsui_container'] {
    background-color: $background-grey !important;
    &::after {
      box-shadow: none !important;
    }
  }
  [class^='awsui_header'] {
    background-color: $background-grey !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    > div {
      padding-left: 2px !important;
    }
  }
  [class^='awsui_content'] {
    padding: 0 !important;
    font-size: 16px !important;
  }
  [class^='awsui_child'] {
    min-height: 36.38px;
  }
}

.report-sidebar-relevant-policymakers,
.report-sidebar-mentions {
  [class^='awsui_handle'] {
    margin-top: 1px;
  }
}

[class*='awsui_dragged'] {
  z-index: 1000000 !important;
  [class^='awsui_header'] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    > div {
      padding-left: 2px !important;
    }
  }
  [class^='awsui_content'] {
    padding: 0 !important;
    font-size: 16px !important;
  }
}

.reports-board-main-container {
  [class^='awsui_header'] {
    position: relative;
    z-index: 1002;
    background: transparent !important;
  }

  [class*='awsui_dragged'] {
    [class^='awsui_header'] {
      > div {
        padding-left: 0px !important;
      }
    }
    div[class^='awsui_header'][class*='awsui_refresh'] {
      padding: 12px !important;
    }
    [class^='awsui_content_'] {
      padding: 4px 20px 20px 20px !important;
    }
  }
}

.sidebar-item-report {
  user-select: none;
  margin-bottom: 0 !important;
  z-index: 100;
  position: relative;
  padding-left: 6px !important;
  .icon-container-location,
  .icon-container-document {
    padding-left: 2px;
    margin-right: -2px;
  }

  .icon-container-briefing-material {
    padding-left: 1px;
    margin-right: -1px;
  }
  .icon-container-key-update,
  .icon-container-statistics {
    font-size: 15px;
    margin-left: -1px;
    margin-right: 1px;
  }

  .icon-container-location {
    font-size: 17px;
  }
  .icon-container-team {
    padding-top: 3px;
  }
  .icon-container-changes {
    padding-top: 2px;
  }
  .icon-container-snapshot {
    padding-top: 1px;
  }
}

.reports-create-macro-button {
  .macro-add {
    margin-top: -2px;
    display: inline-block;
  }
  &:hover {
    text-decoration: none;
  }
}

.macros-plus-icon {
  line-height: 0;
  margin-top: -2px;
  font-size: 24px;
}

.macros-manage-icon {
  margin-top: -4px;
  margin-left: -1px;
  margin-right: 9px;
}

.macros-list-emoji {
  height: 20px;
  max-width: none !important;
  margin-right: 0.85rem !important;
}

.macro-icon-container {
  width: 25px;
  margin-right: 10px;
  margin-top: -4px;
  margin-left: -2px;
  display: inline-block;
  img {
    margin-top: -3px !important;
  }
}

.macro-list-image {
  margin-right: 1rem !important;
  max-width: 18px !important;
}

@media screen and (min-width: 992px) {
  .reports-side-nav {
    .sidebar-item {
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .reports-side-nav [class^='awsui_header'] [class^='awsui_handle'] {
    visibility: hidden !important;
  }

  .reports-side-nav [class^='awsui_header']:hover [class^='awsui_handle'] {
    visibility: visible !important;
  }
}
