.topics-title {
  line-height: 2em;
  font-size: 1.3em;
}

.topics-title-container {
  padding: 0 2em;
}

.topics-description {
  border-left: 2px solid #f2a805;
  padding-left: 1.2em;
  color: rgba(0, 28, 53, 0.6);
  font-style: italic;
  max-width: 650px;
}

.section-subtitle {
  color: rgba(0, 18, 43, 0.75);
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 700;
}

.topic-list-titles {
  font-size: 0.865rem;
  border-bottom: 1px solid #848484;
  h3 {
    font-size: 0.9em;
    color: rgba(0, 18, 43, 0.5);
    @extend .section-subtitle;
  }
  .action-button {
    font-size: 1.1em;
  }
}

.padding-topic-items {
  padding-left: 1em;
  padding-right: 1em;
}

.topic-list-item {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
  -webkit-appearance: none !important;
  outline: none !important;
  @extend .padding-topic-component;
  h4 {
    font-size: 1.1em;
    margin-bottom: 0.55em;
  }
  p {
    color: rgba(0, 18, 43, 0.5);
    margin-bottom: 0;
  }
}

.topic-list-item-name {
  padding-left: 28px !important;
  max-width: calc(100% - 156px);
  position: relative;
  .icon-topics-thin {
    position: absolute;
    left: 1px;
    top: 3px;
    font-size: 18px;
  }
  .dropdown-item-element {
    display: -webkit-box;
    width: 100%;
  }
  &:disabled {
    background: none;
  }
}

.topic-edit-button {
  color: #0094cc;
  &:hover {
    border-bottom: 1px solid #0094cc;
  }
}

.topic-link {
  &:hover {
    text-decoration: none;
  }
}

.topic-frequency {
  color: #00122b !important;
  margin-bottom: 0.4em !important;
  span {
    border-bottom: 1px dashed rgba(0, 18, 43, 0.3);
  }
}

.topic-email-alerts-title {
  font-size: 1.05em !important;
  background: url('#{$cdn-url}envelope-icon-rounded.svg') 0 4px/ 18px auto no-repeat;
  padding-left: 28px;
  margin: 1em 0 !important;
}

.topic-resources {
  max-width: 328px;
  font-size: 0.95em;
}

.no-lists-features {
  li {
    background: url('#{$cdn-url}tick.png') 0 11px / 15px auto no-repeat;
    padding-left: 29px;
  }
}

.no-email-alerts-text {
  color: #e40741 !important;
}

.topic-title {
  font-size: 1.075em;
  font-weight: 700;
}

.topic-modal {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.pricing-feature-tooltip {
  z-index: 1000000 !important;
}
.add-domain-tooltip {
  @extend .pricing-feature-tooltip;
  max-width: 220px;
}
.share-button-tooltip {
  max-width: 220px;
  z-index: 1000000 !important;
  background-color: #00122b !important;
  border-radius: 8px !important;
}
.content-summary-tooltip {
  @extend .pricing-feature-tooltip;
  padding: 8px 15px !important;
}

.button-disabled-action {
  background: rgba(#00122b, 0.1);
  color: rgba(#00122b, 0.35);
  &:hover {
    background: rgba(#00122b, 0.1);
    color: rgba(#00122b, 0.35);
  }
}

.empty-input-field {
  width: 100%;
  border: none;
  &:focus {
    outline: none;
    //border-bottom: 1px dashed rgba(0, 28, 53, 0.3);
  }
  &::placeholder {
    font-weight: 400 !important;
  }
}

.topic-name {
  @extend .empty-input-field;
  font-weight: 700;
  font-size: 1.25rem;
  border-bottom: 1px dashed transparent;
  &:disabled {
    background-color: #fff;
  }
}

.topic-name-container {
  padding-left: 35px;
  background: url('#{$cdn-url}keywordlist-icon.svg') 0 0.3em/20px auto no-repeat !important;
}

.topic-component-description {
  @extend .empty-input-field;
  width: 100%;
  border-bottom: 1px solid rgba($main-grey, 0.8);
  padding: 5px 0px 5px 1em;
  &:hover {
    border-bottom: 1px dashed $main-grey;
  }
  &::placeholder {
    color: rgba($main-blue, 0.5) !important;
  }
}

.topic-component-title {
  &:hover {
    border-bottom: 1px dashed rgba($secondary-blue, 0.3) !important;
  }
  padding-top: 1em !important;
  padding-bottom: 0.8em !important;
}

.topic-action-buttons {
  .action-button,
  .topic-delete-button {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}

.email-alert-close {
  top: 8px;
  right: 8px;
  display: block !important;
}

.keywords-category-words {
  min-height: 85px;
  background: #fcfcfc;
  border-radius: 3px;
  .react-tagsinput {
    background: #fcfcfc;
  }
}
.keywords-category-words-tags-modal {
  @extend .keywords-category-words;
  min-height: unset;
}

.keywords-category-keywords {
  min-height: 120px;
}

.exclusion-button {
  color: rgba(0, 28, 53, 0.7);
}

.action-light-button {
  color: #0094cc;
}

.topic-email-alert-container {
  border: 1px solid #b3bbc3;
  border-radius: 5px;
  color: rgba(#001c35, 1);
}

.topic-email-alert-container.disabled {
  opacity: 0.6;
}

.email-alert-item-maincontent {
  width: calc(100% - 70px);
  margin-bottom: 0;
}

.topic-alert-button {
  width: 18px;
  height: 16px;
  text-indent: -1000px;
  overflow: hidden;
  margin-left: 0.85em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.topic-alert-delete {
  @extend .topic-alert-button;
  background: url('#{$cdn-url}delete-icon-active.svg') center/contain no-repeat;
  &:hover {
    background: url('#{$cdn-url}delete-icon-hover.svg') center/contain no-repeat;
  }
}

.topic-email-disabled {
  color: rgba(0, 28, 53, 0.4);
  .topic-alert-delete {
    background: url('#{$cdn-url}delete-icon-inactive.svg') center/contain no-repeat;
  }
}

.topic-delete-button {
  background: rgba(228, 7, 65, 0.1);
  color: $main-red;
  border-radius: 5px;
  transition: 0.2s ease-in;
  &:hover {
    color: $main-red;
    background: rgba(228, 7, 65, 0.07);
  }
}

.bordered-delete-topic-button {
  border: 1px solid rgba($main-red, 0.3);
}

.topic-email-alerts-content {
  label {
    font-weight: 700;
    font-size: 1.03em;
    margin-bottom: 0;
  }
}

.frequency-dropdown {
  position: absolute;
  top: 35px;
  width: 100%;
  max-height: 226px;
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 100;
  overflow: overlay;
}

.hour-item {
  padding: 0.2em 1rem;
  cursor: pointer;
  &:hover {
    background: rgba(#d4d4d4, 0.2);
  }
}

a.frequency-item {
  color: #212529;
  &:hover {
    text-decoration: none;
  }
}

.frequency-item {
  padding-left: 38px !important;
  cursor: pointer;
  &:hover {
    background-color: rgba(196, 196, 196, 0.1);
  }
}

.frequency-item-selected {
  background: rgba(196, 196, 196, 0.25) url('#{$cdn-url}tick-symbol.svg') 11px 13px / 15px auto no-repeat;
  &:hover {
    background-color: rgba(196, 196, 196, 0.25);
  }
}

.topic-block-button {
  background: rgba(0, 18, 43, 0.4) url('#{$cdn-url}padlock-white.svg') calc(100% - 12px) / 12px auto no-repeat !important;
  padding-right: 32px !important;
}

.remaining-keywords-topic-text {
  color: rgba(0, 18, 43, 0.6);
  font-size: 0.85em;
}

.link-button {
  color: #0094cc;
  margin-top: -1em;
}

.topics-email-alerts-container {
  padding-left: 1.8em;
}

.topics-search-items {
  background: #fcfcfc;
  border: 1px solid #b3bbc3;
  border-radius: 5px;
}

.keywords-rule-title {
  color: rgba(#00122b, 0.7);
  margin-bottom: 0;
  font-size: 0.825em;
  letter-spacing: 0.3px;
  strong {
    font-weight: 700;
    color: #00122b;
  }
}

.keywords-rule-title-closed {
  background: #fff;
  border-top: 1px solid rgba(212, 212, 212, 0.5);
  cursor: pointer;
  &:hover {
    background: rgba(#0094cc, 0.04);
  }
}

.keywords-rule-and-closed {
  border-bottom: 1px solid rgba(212, 212, 212, 0.5);
}

.keywords-exclusions-title {
  border-radius: 0px 0px 5px 5px;
}

.keywords-type-title {
  color: #f2a805 !important;
  font-size: 0.825em;
  letter-spacing: 0.3px;
}

.advanced-title-section {
  margin-top: 1.075em;
}

.resources-dropdown {
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 100;
  overflow: auto;
  padding: 0.65rem 15px 0.65em 1.1em;
  label {
    font-weight: 400;
    letter-spacing: 0px;
    color: #001c35;
  }
}

.resources-dropdown-list {
  padding: 0px 15px 0.65em 1.1em;
}

.hour-select {
  text-decoration: none;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 5px;
  min-width: 125px;
  text-align: left;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  position: relative;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #00122b;
    position: absolute;
    top: 44%;
    right: 10px;
  }
}

.twice-daily-select-container {
  .position-relative {
    width: 100% !important;
    .hour-select {
      width: 100% !important;
    }
  }
}

.hour-select-hidden-arrow {
  cursor: default !important;
  &:hover {
    opacity: 1 !important;
    text-decoration: none !important;
  }
  &::after {
    content: none;
    display: none;
  }
}

.hour-select-opendropdown {
  border-radius: 5px 5px 0px 0px;
  &.cut-at-bottom {
    border-radius: 0px 0px 5px 5px;
  }
  &::after {
    border-top: none;
    border-bottom: 6px solid #00122b;
  }
}

.padlock-title-item {
  width: 11px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -4px;
}

/* .resources-dropdown{
  height: 206px;
  width: 100%;
} */

.padlock-email-alert {
  width: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
  margin-left: 6px;
}

.hand-picked-alertlist {
  margin: -10px 0 25px 0;
  padding-left: 1.85em;
}

.handpicked-greyout {
  label {
    color: rgba(0, 18, 43, 0.6);
    &::before {
      border: 2px solid rgba(0, 18, 43, 0.7);
    }
  }
}

.hand-picked-recommended {
  color: #0094cc;
  font-size: 0.9em;
  font-style: italic;
}

.topics-scrollbar {
  @extend .main-content-scrollbar;
}
.keywords-list-summary {
  color: #00122b !important;
  margin-bottom: 0.4em !important;
  padding-left: 1.8em;
  span {
    margin-left: 0.35em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.15;
    margin-top: -5px;
    border-bottom: 1px dashed rgba(0, 18, 43, 0.3);
  }
}

.box-topics-list {
  position: fixed;
  display: none;
  top: 20px;
  left: 0;
  width: 400px;
  padding: 0;
  p {
    color: #00122b !important;
  }
}

.box-category-list {
  width: 480px;
  top: -50%;
  left: 100%;
  display: none;
  visibility: hidden;
}

.topic-property-item-summary {
  color: #00122b !important;
  margin-left: 0.5em;
  border-bottom: 1px dashed rgba(0, 18, 43, 0.3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.15;
  margin-top: -3px;
}

.no-keywords-selected {
  color: rgba(0, 18, 43, 0.4) !important;
  font-style: italic;
  border: none !important;
}

.category-item-summary {
  border: 1px solid rgba(212, 212, 212, 0.5);
  padding: 8px 8px 8px 18px;
}

.category-words-summary {
  padding: 10px 18px 20px 18px;
  li {
    margin-bottom: 6px;
    padding-left: 21px;
    color: rgba(#00122b, 0.7);
  }
}

.resources-list-checked {
  color: #00122b !important;
  background: url('#{$cdn-url}tick-symbol.svg') 0px 7px / 13px auto no-repeat;
}

.edit-summary-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: url('#{$cdn-url}edit-pencil.svg') center / 15px auto no-repeat;
  text-indent: -1000px;
  overflow: hidden;
}

.sticky-item {
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 100;
}

.sticky-topic-table-headers {
  top: 42px;
}

.email-alerts-information-main {
  padding-left: 1.8em;
}

.padding-topic-component {
  padding: 0 1.25em;
}

.topic-category-tooltip {
  max-width: 300px;
  a {
    color: $main-white;
    text-decoration: underline;
  }
}

.add-email-empty-button {
  margin-top: -12px;
}

.resources-summary-main {
  display: block !important;
  .topic-frequency {
    margin-bottom: 0.2em !important;
  }
  .topic-resources-item-summary {
    margin-left: 0;
    margin-bottom: 0.7em;
  }
}

.advanced-options-button {
  transition: 0.2s ease-in;
  &:hover {
    background-color: rgba($light-blue, 0.08);
  }
  p {
    color: rgba($main-blue, 0.7);
    padding-left: 4px;
    position: relative;
    display: inline-block;
    padding-right: 20px;
    &:after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid rgba($main-blue, 0.7);
      position: absolute;
      top: 43%;
      right: 0px;
    }
  }
}

.create-email-alert-label {
  font-weight: 400 !important;
  font-size: 1em !important;
  margin-bottom: 0.5em !important;
  .pricing-feature-more {
    margin-top: -2px;
  }
}

.topics-alerts-dropdown {
  .checkbox-item {
    &:last-child {
      .create-email-alert-label {
        margin-bottom: 0em !important;
      }
    }
  }
}

.digest-combining-topics-space {
  padding-left: 18px;
}

.create-email-alert-simple-modal {
  .sort-mode-radio-stakeholders {
    border-bottom: 0;
  }
}

.create-download-chart-simple-modal {
  .sort-mode-radio-stakeholders {
    border-bottom: 0;
  }
}

.existing-email-alert-dropdown {
  padding-left: 34px;
  .hour-select {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
}

.resume-email-alert-item {
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.email-alerts-dropdown {
  .checkbox-item {
    &::before {
      top: 5px;
    }
  }
  label {
    line-height: 1.75 !important;
    &::before,
    &::after {
      top: 5px;
      border-radius: 0px;
    }
  }
}

.topics-alerts-dropdown {
  .create-email-alert-label {
    &::before,
    &::after {
      top: -1px;
    }
  }
}

.email-alerts-create-topic {
  label {
    display: block !important;
    padding: 0 0 0 34px !important;
    height: auto;
  }
}

.keywords-description-topic {
  transition: 0.2s ease-in;
  position: relative;
  cursor: text;
}

.dropdown-item-element {
  position: relative;
  padding-right: 20px;
  display: inline-block;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid currentColor;
    position: absolute;
    top: 8px;
    right: 0px;
  }
}
.dropdown-item-date-element {
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
  display: block;
}

@for $i from 9 through 20 {
  .dropdown-item-element-#{$i} {
    &::after {
      top: #{$i}px;
    }
  }
}

.dropdown-item-element.open {
  &::after {
    border-top: none;
    border-bottom: 6px solid currentColor;
  }
}

.additional-subject-line-input {
  padding: 0.375rem 0.75rem;
  color: $main-blue;
  border-radius: 0.25rem;
  background-color: #fff;
  max-height: 38px;
  min-height: 38px;
  font-size: 16px;
  input {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 20px;
    margin-left: 8px;
    &:focus,
    &:hover {
      outline: none;
      border: none;
    }
  }
  .topic-list-placeholder {
    background: rgba($light-blue, 0.08);
    border: 1px solid rgba($main-grey, 0.5);
    padding: 3px 8px;
    background-position: 8px 5px;
    background-size: 13px auto;
    border-radius: 3px;
    margin-left: 4px;
  }
}

.topics-hide-checkbox {
  width: auto;
  height: 19px;
  position: relative;
  display: inline-block;
  left: 0;
  top: 0;
  margin-right: 11px;
}

.hide-topic-btn-container {
  border-top: 1px solid rgba(#d4d4d4, 0.5);
}

.hide-topic-label-wrapper-topics {
  font-size: 15px;
}

.hide-topic-btn {
  border-radius: 20px;
  border: 1px solid #d4d4d4;
  background: #fff;
  width: fit-content;
  padding: 2px 11px 1px 9px;
  .hide-topic-btn-icon {
    color: rgba(#00122b, 0.85);
    margin-right: 8px;
    font-size: 13px;
  }
  .icon-eye-hide {
    font-size: 19px;
    margin-right: 6px;
  }
  .icon-info {
    font-size: 16px;
  }
}

.topics-hide-checkbox-dropdown {
  left: 8px;
  top: 11px;
  border-radius: 3px;
}

.advanced-rules-button {
  background: $main-white;
  border-radius: 0px 0px 5px 5px;
}

.keywords-adjustment {
  padding: 0px 18px;
}

.individual-topic-subtitle {
  font-size: 1em;
  color: #212529 !important;
}

.individual-topic-actual-text {
  font-size: 1em;
  line-height: 1.5em;
}

.individual-items-vertical-padding {
  padding-top: 1.2em !important;
  padding-bottom: 1.35em !important;
}

.adimn-keywords-suggestions {
  width: calc(100% + 32px);
  margin-left: -16px;
  .topic-suggestions-container-placeholder {
    margin-left: 3px;
  }
}

.topic-suggestions-container {
  background-color: #f5fbfd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .topic-suggestions-container-placeholder {
    color: #00122b !important;
    margin-top: 2px;
    margin-bottom: 3px;
  }
  .topic-suggestions-list-title {
    margin-top: 2px;
    margin-bottom: 2.5px;
  }
  .suggestions-list-scrolled {
    transform: translateX(-5px);
    &::after {
      content: '';
      left: -10px;
      display: block;
      height: 30px;
      width: 30px;
      top: 0;
      position: absolute;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 30%, #f5fbfd 70%);
    }
  }
  .topic-suggestions-list {
    display: flex;
    column-gap: 12px;
    z-index: 0;
    .swiper-slide {
      width: auto !important;
    }
    &.swiper-backface-hidden {
      &::after {
        display: none !important;
      }
    }
  }
  .topic-suggestions-list-item {
    width: fit-content;
    white-space: nowrap;
    border: 1px solid rgba(#00122b, 0.5);
    border-radius: 5px;
    padding: 1px 12px 2px 12px;
    color: rgba(0, 18, 43, 0.7);
    cursor: pointer;
    background-color: #fff;
    user-select: none;
    display: flex;
    align-items: center;
    .keyword-suggestion-plus {
      font-size: 26px;
      font-weight: 700;
      text-align: left;
      line-height: normal;
      line-height: 24px;
      margin-left: 9px;
    }
    &:hover {
      color: #0094cc;
      border: 1px solid #0094cc;
    }
  }
  .topic-suggestions-scroll-arrow {
    position: absolute;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid rgba(0, 18, 43, 0.5);
    background-color: #fff;
    &:disabled {
      opacity: 0.7;
      &::after {
        background: none !important;
      }
    }
    &:hover:not(&:disabled) {
      background-color: #f6f6f6;
    }
    &:before {
      content: '';
      height: 12px;
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      content: '';
      display: block;
      height: 30px;
      width: 30px;
      top: -1px;
      position: absolute;
      z-index: 1;
    }
    &.direction-right {
      right: -30px;
      &:before {
        content: '';
        border: 8px solid transparent;
        border-left: 10px solid #00122b;
        left: 20px;
      }
      &::after {
        left: -31px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 30%, #f5fbfd 70%);
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .resources-summary-main {
    display: flex !important;
    .topic-frequency {
      margin-bottom: 0.4em !important;
    }
    .topic-resources-item-summary {
      margin-left: 0.5em;
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 576px) {
  .keywords-list-summary {
    background: url('#{$cdn-url}tag-explanation-icon.svg') 23px 7px / 13px auto no-repeat;
    padding-left: 3em;
  }

  .topic-property-item-summary {
    max-width: 220px;
  }
}

.topics-scrollbar {
  .ScrollbarsCustom-Track {
    top: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .topic-list-item-name {
    max-width: calc(100% - 190px);
  }
}

@media screen and (min-width: 992px) {
  .topic-component-description {
    padding-left: 42px;
  }

  .box-category-list {
    width: 350px;
  }

  .sticky-item-lg {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 100;
  }

  .email-alerts-information-main {
    padding-left: 0em;
  }
  .box-topics-list {
    display: block;
  }

  .box-category-list {
    display: block;
  }

  .topic-property-item-summary {
    max-width: 100px;
  }

  .edit-summary-button {
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: rgba(0, 148, 204, 0.08);
    }
  }

  .topic-edit-button {
    visibility: hidden;
  }

  .padding-topic-items-lg {
    padding-left: 3.6em;
    padding-right: 2.8em;
  }

  .padding-topic-items {
    @extend .padding-topic-items-lg;
  }

  .padding-topic-component {
    padding: 0 45px 0 40px;
  }

  .topic-list-item {
    padding-right: 0px;
    padding-left: 0px;
    transition: 0.2s ease-in;
    h4 {
      margin-left: -28px;
    }
    &:hover {
      background: rgba(0, 148, 204, 0.04);
      .topic-edit-button {
        visibility: visible;
      }
    }
  }

  .topic-individual-padding {
    padding-right: 70px;
    padding-left: 70px;
  }

  .topics-scrollbar {
    width: 100% !important;
    margin-left: 0 !important;
    .ScrollbarsCustom-ThumbY {
      background-color: #99a4ae !important;
      right: 20px !important;
    }
    .ScrollbarsCustom-Content {
      padding: 0 !important;
    }
    .ScrollbarsCustom-Wrapper {
      inset: 0px 0px 0px 0px !important;
    }
  }

  .email-alert-close {
    right: 20px;
    top: 24px;
  }

  .add-email-alert-button {
    width: 100%;
    display: block;
    text-align: left;
    border: 1px dotted transparent;
    &:hover {
      border: 1px dotted rgba(#d4d4d4, 1);
      border-radius: 5px;
    }
  }

  .topic-frequency {
    background: none;
    padding-left: 0px;
  }
  .topic-items-container {
    //padding-left: 40px;
  }

  .topic-badges-margin-adjustment {
    margin-top: 10px;
  }

  .keywords-adjustment {
    padding: 0px 28px;
  }

  .topic-alert-edit {
    &:hover {
      color: #f2a805 !important;
    }
  }
}

@media screen and (min-width: 1200px) {
  .topic-list-item-name {
    max-width: calc(100% - 460px);
  }

  .topic-badges-margin-adjustment {
    margin-top: 0;
  }
}

@media screen and (min-width: 1238px) {
  .topic-property-item-summary {
    max-width: 220px;
  }
}

@media screen and (min-width: 1300px) {
  .resources-summary-main {
    .box-topics-list {
      width: 400px;
    }
  }
  .box-category-list {
    width: 480px;
  }
}
