$icon-classes: (
  'news': '20210107-icon-key-updates',
  'consultation': ' ',
  'legislation': ' ',
  'parliamentary': '20210107-icon-parliamentary-recording',
  'upcoming-events': '20210107-icon-upcoming-events',
  'social': '20210107-icon-social-media',
  'stakeholders': '20210220-icon-stakeholders',
  'policy-asks': '20210208-icon-policy-asks',
  'briefing-material': '20210208-icon-briefing-material',
  'lobbying-material': '20210208-icon-briefing-material',
  'have-your-voice-heard': '20210208-icon-briefing-material',
  'all': '20220103-icon-unread-items',
  'research-mobile': 'icon_header_search',
  'all-v2': '20220103-icon-unread-items',
  'library-material': '202203101-icon-library-material',
  'dashboard': '20220818-icon-dashboard',
  'mentions': '20220818-icon-mentions',
  'tweets': '20220818-icon-tweets-1',
  'sentiment-snapshot': '20220818-icon-sentiment-snapshot',
  'sentiment-changes': '20220818-icon-sentiment-changes',
  'top-mps-and-peers': '20220818-icon-top-stakeholders',
  'stakeholder-mapping': '20220818-icon-top-stakeholders',
  'my-contacts': '20211028-team-icon',
  'email-campaigns': '20211125-email-alerts-icon',
  'inbox': '20211125-email-alerts-icon',
  'my-lobbying-material': '20211028-my-teams-content-icon',
  'engagement-statistics': '20220818-icon-mentions',
  'influence-inbox': 'influence_inbox_h_16',
  'campaigns': '20210208-icon-briefing-material',
  'email-performance': 'sent-performance',
);

@each $icon, $icon-class in $icon-classes {
  $base-date: '20210107-icon-#{$icon}';
  @if ($icon-class != ' ') {
    $base-date: $icon-class;
  }
  $url: '#{$cdn-url}#{$base-date}';
  .#{$icon}-a {
    .item-icon {
      background-image: url('#{$url}.svg');
    }
  }
  .#{$icon}-a.active {
    .item-icon {
      background-image: url('#{$url}-blue.svg');
    }
  }
}

.main-navigation {
  position: fixed;
  font-size: 15px;
  z-index: 20000;
  width: 75%;
  max-width: 380px;
  height: 100%;
  overflow-x: hidden;
  transition: 0.2s ease-in;
  background: #fff;
  top: 0;
  left: -100%;
  padding-top: 5px; //intercom adjustment
  .active {
    .notification-count {
      color: $hover-blue;
    }
  }
  .close-image {
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
    width: 13px;
    z-index: 3;
  }
}

.main-navigation-margin {
  margin-top: 46px;
}

.sidenav-subtitle {
  padding-left: 40px;
}

.sidenav-subtitle-influence {
  margin-top: 28px;
}
.sidenav-subtitle-influence-page {
  margin-top: 22px;
}

.influence-side-bar {
  margin-top: -30px;
}

.side-left-group-page {
  padding-top: 32px;
}

.header-mobile-sidenav {
  .main-navigation {
    left: -100%;
  }
}

.show-menu {
  left: 0 !important;
}

.navigation-container {
  height: 100%;
}

.main-navigation-logo {
  img {
    width: 140px;
  }
  .login-arrow {
    width: 15px;
  }
  .profile-picture {
    width: 40px;
  }
}

.main-nav {
  border-bottom: 0.5px solid #e0e0e0;
}

.sidebar-nav {
  h3 {
    color: #848484;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 20px !important;
  }
  ul {
    list-style: none;
  }
  .political-updates-nav-icon {
    width: 30px;
  }
}

.sidebar-item {
  position: relative;
  display: block;
  color: #022344;
  padding: 0.45rem 20px;
  margin-bottom: 0.175rem;
  min-height: 36.38px;
  &:hover {
    text-decoration: none;
  }
  .item-icon {
    margin-right: 0.75rem;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  img {
    margin-right: 0.75rem;
    margin-top: 1px;
    max-width: 15px;
  }
  .pro-feature {
    background-image: url('#{$cdn-url}pro-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -10000px;
    width: 30px;
    height: 20px;
    right: 0.5rem;
    top: 21%;
    position: absolute;
  }
}

.sidebar-item.active {
  background-color: #ebf7fb;
  color: $light-blue;
  &:hover {
    color: $light-blue;
    background-color: #ebf7fb;
  }
}

.sidebar-item-container {
  padding-top: 1px;
}

.sidebar-key-update {
  font-size: 14px;
  padding-top: 2px;
}

.sidebar-legislation,
.sidebar-calendar {
  padding-top: 0px;
  font-size: 16px;
}

.sidebar-parliamentary-record {
  padding-left: 1px;
  margin-right: -1px;
}

.sidebar-library-material {
  font-size: 13px;
  padding-top: 3px;
}

.sidebar-briefing-material {
  padding-left: 2px;
  margin-right: -2px;
}

.sidebar-document {
  font-size: 18px;
  padding-left: 2px;
  margin-right: -1px;
  padding-top: 0;
  margin-bottom: -6px;
  margin-top: -1px;
}

.influence-inbox-a {
  .item-icon {
    margin-top: 3.5px !important;
    margin-left: 1px;
    margin-right: 11px !important;
  }
}

.news-a {
  .item-icon {
    margin-top: -0.6px;
    width: 17px;
    height: 14px;
  }
}

.consultation-a {
  .item-icon {
    height: 15px;
  }
}

.legislation-a {
  .item-icon {
    margin-top: -1px;
  }
}

.parliamentary-a {
  .item-icon {
    margin-top: 2px;
  }
}

.stakeholders-a {
  .item-icon {
    margin-top: 1px;
    width: 18px;
  }
}

.my-lobbying-material-a {
  .item-icon {
    margin-top: 2px !important;
  }
}

.policy-asks-a {
  .item-icon {
    margin-top: 1px;
    width: 18px;
  }
}

.briefing-material-a,
.have-your-voice-heard-a {
  .item-icon {
    margin-left: 2px;
  }
}

.research-mobile-a {
  .item-icon {
    margin-top: 2px;
  }
}

.library-material-a {
  .item-icon {
    width: 18px;
    margin-top: 5px;
    margin-left: -1px;
  }
}

.tweets-a,
.mentions-a,
.sentiment-snapshot-a,
.sentiment-changes-a,
.top-stakeholders-a {
  .item-icon {
    margin-top: 3px;
  }
}

.sentiment-changes-a {
  .item-icon {
    width: 18px;
    margin-left: -1px;
  }
}

.stake-holders-coming-soon {
  position: absolute;
  top: 100%;
  left: 1em;
  font-size: 0.85em;
  padding: 0.5em 1em;
  background: #00122b;
  color: #fff;
  border-radius: 5px;
  display: none;
  a {
    color: #f2a805;
    display: block;
    margin-top: 0.5em;
    text-decoration: underline;
  }
}

.show-coming-soon {
  color: #848484;
  .item-icon {
    background-image: url('#{$cdn-url}icon-social-media-grey.svg');
  }
}

.social-a-disabled {
  &:hover {
    color: #848484;
    background-color: transparent !important;
  }
}

.coming-soon {
  font-size: 0.75em;
  padding: 0.12rem 0.4rem;
  margin-left: 0.5rem;
  border-radius: 5px;
  border: 1px solid #00122b;
}

.new-item {
  border: 1px solid #f2a805;
  color: #f2a805;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.65em;
  padding: 2px 5px;
  margin-left: 0.85em;
}

.separator {
  border-top: 0.5px solid #e0e0e0;
  margin-bottom: 0.75rem !important;
}

.notification-count {
  min-width: 30px;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 0.675em;
  border-radius: 3px;
  padding: 3px 5px 3px 5px;
  background: #ffffff;
  border: 1px solid rgba(212, 212, 212, 0.5);
  text-align: center;
  color: rgba(0, 18, 43, 0.6);
}

.notification-count-skeleton,
.notification-count {
  position: absolute;
  right: 10px;
  top: 6.5px;
}
.notification-count-skeleton {
  width: 30px;
  height: 21px;
  border: 1px solid rgba(212, 212, 212, 0.5);
  background-color: #fff;
  border-radius: 3px;
  span {
    display: inline-block !important;
    margin-top: -2px !important;
    vertical-align: middle !important;
  }
}

.notification-count-unseen {
  &::after {
    width: 6px;
    height: 6px;
    position: absolute;
    top: -3px;
    right: -2px;
    content: '';
    border-radius: 50%;
    background: #f2a805;
  }
}

.bottom-terms {
  width: 100%;
  font-size: 0.8em;
  max-width: 300px;
  padding-left: 20px;
  color: rgba($soft-grey, 0.8);
  a {
    color: rgba($soft-grey, 0.8);
    line-height: 1.3rem;
    font-weight: 400;
    &:hover {
      text-decoration: none;
      color: #848484;
    }
  }
  .dot {
    color: rgba($soft-grey, 0.8);
    width: 8px;
    height: 8px;
    transform: scale(0.4);
    margin: 0 7px;
  }
}

.bottom-terms-container {
  border-top: 0.5px solid #e0e0e0;
}

.btn-menu {
  position: absolute;
  top: 9px;
  img {
    margin-top: 8px;
  }
}

.hamburger-button {
  img {
    width: 24px;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.close-menu {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 20000;
}

.container-close-menu {
  background-color: rgba(2, 35, 68, 0.45);
}

.monitor-title {
  margin-top: 1.2rem;
  padding: 0 0 0.1rem 0;
}

.influence-title {
  padding: 0.5rem 0 0 0;
}

.main-navigation-mobile {
  padding-left: 20px;
}

.main-navigation {
  .ScrollbarsCustom-Content {
    height: 100%;
  }
}

.navigation-parliamentarians {
  .monitor-title,
  .influence-title {
    display: none;
  }
}

.sidebar-nav-pages {
  .static-page-link {
    margin-top: 0 !important;
    padding-left: 20px;
  }

  .static-page-monitor {
    margin-top: 10px !important;
  }

  .influence-header-box {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none;
    border: none;
    padding-top: 0;
    padding-left: 20px;
    margin-top: -20px;
  }
  .dropdown-item-element {
    display: inline-block !important;
    &::after {
      top: 14px;
    }
  }
}
.search-nav-button-container {
  padding: 0 20px;
  border-top: 1px solid rgba($main-grey, 0.5);
  border-bottom: 1px solid rgba($main-grey, 0.5);
  background: rgba($main-grey, 0.08);
}

.sidenav-searchbar {
  .main-search-icon {
    margin-top: -2px;
    margin-right: 6px;
  }
  .search-main-input {
    margin-top: 0px !important;
    font-size: 16px !important; //avoid zoom on iphone
  }
}

.analytics-static-link {
  + .intercom-namespace {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .main-navigation {
    transition: all 0s ease 0s;
    z-index: 300;
    left: 0;
  }

  .sidebar-nav {
    padding-top: 16px;
    h3 {
      padding-left: 38px !important;
    }
  }

  .bottom-terms {
    padding-left: 38px;
  }

  .sidebar-item {
    border-radius: 0 5px 5px 0;
    padding: 7px 16px 7px 16px;
    &:hover {
      text-decoration: none;
      color: #022344;
      background-color: #f6f6f6;
    }
  }

  .main-navigation-logo {
    padding: 1.4rem 2.5rem;
  }
  .main-navigation {
    position: relative;
    left: 0;
    width: 275px;
    height: 100%;
    visibility: visible;
    opacity: 1;
    background: transparent;
  }

  .show-coming-soon {
    &:hover {
      .coming-soon {
        display: block;
      }
    }
  }
}

.sidenav-list {
  transition: 0.2s ease-in;
}

.unstyled-list {
  list-style: none !important;
}

@media screen and (min-width: 1290px) {
  .analytics-static-link {
    + .intercom-namespace {
      display: inline-block !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  .sidebar-item {
    border-radius: 5px;
  }
}
