.legislation-list {
  h3 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.legislation-commons,
.stakeholders-table-commons {
  color: #106f53;
}

.legislation-grey {
  color: #33495d;
}

.legislation-lords,
.stakeholders-table-lords {
  color: #b40a37;
}

.legislation-container {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(2, 35, 68, 0.2);
  }
}

.legislation-tooltip {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  top: 30px;
  left: -14px;
  width: 128px;
}

.primary-legislation-list {
  li {
    @extend .section-title;
  }
}

.secondary-container {
  height: 400px !important;
}
.primary-container {
  max-height: 300px;
}

.legislation-list-auto {
  height: auto !important;
}

.legislation-source {
  color: #848484 !important;
  font-size: 0.93rem;
  &:hover {
    color: #848484;
    text-decoration: none;
  }
}

.legislation-title {
  @extend .content-title;
  width: 100%;
  //background: url('#{$cdn-url}icon-legislation-v1.svg') left 2px/ 16px auto no-repeat;
  //padding-left: 2em;
}
/* 
.primary-active{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.secondary-active{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
} */

.legislation-content-in {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.1rem;
  }
  h3 {
    margin-bottom: 0.3rem;
  }
  p,
  ul {
    margin-bottom: 1rem;
  }
}
.Dropdown-root {
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1000;
}

.Dropdown-control {
  border: none !important;
  border-radius: 8px !important;
  padding: 3px 52px 3px 18px !important;
}
.Dropdown-menu {
  top: 90% !important;
  border: none !important;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25) !important;
}

.Dropdown-arrow {
  background-image: url('#{$cdn-url}arrow-down.svg') !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px !important;
  height: 14px !important;
  top: 13px !important;
  border: none !important;
}
.Dropdown-option {
  padding: 6px 19px !important;
}

.is-open {
  .Dropdown-arrow {
    transform: rotate(180deg);
    top: 8px !important;
  }
  .is-selected {
    background: url('#{$cdn-url}tick-symbol.svg') 3px center no-repeat;
    padding-left: 19px;
    margin-left: -15px;
  }
}

.current-house-item {
  p {
    margin-bottom: 0;
    color: #848484;
  }
  .legislation-commons {
    border: none;
    color: #006548;
  }
  .legislation-grey {
    border: none;
    color: rgba(0, 28, 53, 0.6);
  }
  .legislation-lords {
    border: none;
    color: #b91943;
  }
  .legislation-royal {
    border: none;
    color: #373151;
  }
}

.house-item {
  display: inline-block;
}

@media screen and (min-width: 992px) {
  .primary-container {
    max-height: 42.7%;
  }
  .legislation-container {
    visibility: hidden;
    &:hover {
      visibility: visible;
    }
    &:focus {
      visibility: visible;
    }
  }

  .legislation-content {
    visibility: visible;
  }
}

@media screen and (min-width: 1024px) {
  .legislation-icon:hover + .legislation-tooltip {
    display: block;
  }
  .legislation-icon:hover + .legislation-tooltip {
    display: block;
  }
}
