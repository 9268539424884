.biography-element {
  opacity: 0.7;
  font-size: 0.9125em;
  line-height: 1.5;
}

.biography-element-hidden {
  -webkit-line-clamp: 3;
  max-height: 64px;
  margin-bottom: 6px;
  .content {
    @extend .hidden-lines;
    max-height: 64px;
    -webkit-line-clamp: 3;
  }
  p {
    margin-bottom: 0;
  }
}

.biography-container {
  border: none !important;
  .dropdown-item-element {
    &:after {
      top: 6px !important;
    }
  }
}

.toggle-no-button {
  -webkit-appearance: none !important;
  outline: none !important;
}

.biography-secondary-container {
  border-radius: 0px 0px 5px 5px;
}

.basic-information-empty-adjustment {
  margin-bottom: -10px;
}

.biography-controllers-button {
  padding: 0.3em 1em;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authenticated-stakeholder-tabs {
  .biography-controllers-button {
    margin: 0 !important;
    flex: 1;
    top: 147px;
    border-radius: 0px !important;
    border-left: none !important;
    &:last-of-type {
      border-right: none !important;
    }
  }
}

.note-visibility-select {
  align-self: start;
  margin-top: 2px;
  & > .general-button {
    border-right: 1px solid rgba(#d4d4d4, 0.5);
    border-radius: 0 !important;
    padding-right: 18px;
  }
}

.biography-controller-icon-button.disabled {
  background: $mercury;
  color: rgba($main-blue, 0.6);
}
.biography-controller-main-button {
  &:disabled {
    background: $mercury;
    color: rgba($main-blue, 0.6);
  }
}
.add-contact-special-adviser {
  border: 1px solid #d4d4d4;
}

.note-container {
  word-break: break-word;
  padding-bottom: 5.8px;
  line-height: 1.4;
  color: rgba($main-blue, 0.85);
  white-space: pre-wrap;
}

.activity-log-tab {
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.activity-log-tab.active {
  border-bottom: 2px solid $main-blue;
}

.notes-textarea {
  padding: 0;
  resize: none;
  height: auto;
  line-height: 1.4;
  min-height: 23px;
}
.notes-textarea-tooltip {
  width: 45px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  right: -16px;
  color: rgba($main-blue, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 21px;
  padding-top: 4px;
  .icon-eye-hide {
    height: fit-content;
  }
}

.notes-textarea-disabled {
  background-color: #efefef;
  color: rgba($main-blue, 0.5);
  .notes-textarea {
    color: rgba($main-blue, 0.5);
    background-color: #efefef;
  }
}

.user-notes-textarea {
  &:disabled {
    color: rgba($main-blue, 0.3);
    background-color: transparent;
  }
}

.user-note-container {
  padding-top: 0.1em;
  padding-bottom: 0.3em;
}

.user-note-action-buttons {
  padding-top: 2px;
  min-width: 60px;
  .blue-text {
    &:hover {
      opacity: 0.8;
    }
  }
}

.delete-note-button {
  border-radius: 5px;
  background-color: #9b171f;
  transition: 0.2s ease-in;
  &:hover {
    background-color: rgba(#9b171f, 0.7) !important;
  }
  &:disabled {
    background-color: rgba($main-grey, 0.2) !important;
    color: rgba($main-blue, 0.4);
  }
}

.spacing-icon-email {
  vertical-align: middle;
  margin-top: 3px;
}

.stakeholder-email-item {
  .dropdown-item-element {
    position: absolute;
    top: 1.4em;
    right: 1.5em;
  }
}

.profile-image-content {
  .icon-save {
    color: rgba(0, 18, 43, 1) !important;
  }
  .circle-btn-with-hover {
    &:hover {
      background-color: #e9f2f5 !important;
    }
  }
}

.note-name-item {
  margin-top: -4px;
  span {
    margin-top: 4px;
  }
}

.stakeholder-email-subject {
  font-size: 0.92rem;
  line-height: 1.2;
  margin-bottom: 0.35em !important;
}
.stakeholder-email-description {
  font-size: 0.9125em;
}

@media screen and (min-width: 992px) {
  .profile-scrollbar {
    .ScrollbarsCustom-TrackY {
      right: 9px !important;
    }
  }
  .profile-scrollbar.trackYVisible {
    .ScrollbarsCustom-Content {
      padding-right: 1px !important;
    }
  }
  .biography-general-container {
    flex: 0 0 44%;
    max-width: 44%;
  }

  .user-note-action-buttons {
    visibility: hidden;
  }

  .user-note-main-container {
    &:hover {
      .user-note-action-buttons {
        visibility: visible;
      }
    }
  }

  .stakeholder-item-title {
    transition: 0.2s ease-in;
    &:hover {
      background: rgba($light-blue, 0.06);
    }
  }
}

@media screen and (min-width: 1280px) {
  .biography-controllers-button {
    font-size: 1em;
  }
  .biography-controllers-button {
    padding: 0.3em 1.41em;
  }
}
