.heading-date {
  font-size: 0.75rem;
  color: #848484;
  letter-spacing: 0.3px;
  .dot {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    background-color: rgba(#00122b, 0.8);
  }
  strong {
    font-weight: 600 !important;
  }
}

.arrow-down {
  @extend .dashbboard-dots;
  img {
    margin-top: -5px;
  }
}

.pro-feature {
  color: $hover-blue;
  font-size: 0.7rem;
  font-weight: 700;
}

.dashbboard-dots {
  transform: rotate(90deg);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in;
  &:hover {
    background: rgba(132, 132, 132, 0.16);
  }
  img {
    width: 15px;
    margin-top: -4px;
  }
}

@media screen and (min-width: 576px) {
  .heading-date {
    .date {
      font-size: 1em;
    }
  }
}
